import React, { ChangeEvent, useEffect, useState } from 'react';
import { FaSpinner, FaUserCheck } from 'react-icons/fa';
import * as S from './styles';


import chatService from '../../service/chatService';

import { ArrowBack, Close } from '@mui/icons-material';
import * as MUI from '@mui/material';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { FaExchangeAlt } from "react-icons/fa";
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { ChatItemModel } from '../../models/redux-models';
import companyService from '../../service/companyService';
import configService from '../../service/configService';
import departmentService from '../../service/departmentService';
import userService from '../../service/userService';
import webhookService, { IWebhook } from '../../service/webhookService';
import { changeNameUserChat, chatActions, fetchCurrentChatAndsChats, socketChatEnd } from '../../store/chat-actions';
import { initialChat } from '../../store/chat-slice';
import ChatHeaderAttendant from '../ChatHeaderAttendant';
import ImageCompany from '../ImageCompany';
import { ModalButtonRow, ModalTitle, StyledDialog } from './styles';

export interface DepartmentItems {
    name: string;
    id: string;
}

interface ISelectWebhook {
    key: string,
    value: string
}

const ChatHeader: React.FC<{ chat: ChatItemModel }> = (props) => {

    const [isWaitingRequest, setWaitingRequest] = useState(false);
    const [isWaitingTransferRequest, setWaitingTransferRequest] = useState(false);

    const [modalState, setModalState] = useState<boolean>(false);
    const [modalChangeAgent, setModalChangeAgent] = useState<boolean>(false);
    const [modalStateAttendanceTransfer, setModalStateAttendanceTransfer] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState<string>("");
    const [attendantName, setAttendantName] = useState<string>("Nenhum atendente");
    const [open, setOpen] = React.useState(false);
    const [companySelected, setCompanySelected] = useState<string>("");
    const [departmentSelected, setDepartmentSelected] = useState<string>("");
    const [userSelected, setUserSelected] = useState<string>("");

    const [optionsCompany, setOptionsCompany] = useState<Array<{ value: string; label: string; }>>();
    const [optionsDepartment, setOptionsDepartment] = useState<Array<{ name: string; id: string; }>>();
    const [optionsUser, setOptionsUser] = useState<Array<{ name: string; id: string; }>>();

    const [webhooks, setWebhooks] = useState<IWebhook[]>([]);
    const [selectSearchOptions, setSelectSearchOptions] = useState<ISelectWebhook[]>([]);
    const [selectedOption, setSelectedOption] = useState<string>();
    const [webhookClose, setWebhookClose] = useState<string>("invalid");

    //configs
    const [discardChat, setDiscardChat] = useState<boolean>(true);
    const [allowChangeUsername, setAllowChangeUsername] = useState<boolean>(false);
    const [blockRegisterTicketWithoutAgent, setBlockRegisterTicketWithoutAgent] = useState<boolean>(false);
    const [showCustomerFieldsOnTicketRegister, setShowCustomerFieldsOnTicketRegister] = useState<boolean>(false);
    const [showTicketFieldsOnTicketRegister, setShowTicketFieldsOnTicketRegister] = useState<boolean>(false);

    //form fields
    const [customerLogin, setCustomerLogin] = useState<string | null>(null);
    const [customerEmail, setCustomerEmail] = useState<string | null>(null);
    const [customerName, setCustomerName] = useState<string | null>(null);
    const [customerCompanyName, setCustomerCompanyName] = useState<string | null>(null);
    const [ticketTitle, setTicketTitle] = useState<string | null>(null);
    const [ticketIsClosed, setTicketIsClosed] = useState<boolean>(false);
    const [isCustomerUserFieldsBlocked, setIsCustomerUserFieldsBlocked] = useState<boolean>(false);
    const [invalidSelect, setInvalidSelect] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const chat = useAppSelector(state => state.chat.chat)

    const [isHovered, setIsHovered] = useState(false);

    const handleChangeUserSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        setUserSelected(e.target.value);
    };

    const handleChangeDepartmentSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        setDepartmentSelected(e.target.value);
    };

    const customStyles = {
        content: {
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '400px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'var(--background)',
            border: '1px solid var(--background)',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
        }
    };

    const togleModal = () => {
        setWebhookClose("invalid");
        setModalState(!modalState);
    }

    const togleModalStateAttendanceTransfer = () => {
        setUserSelected("");
        setDepartmentSelected("");
        setModalStateAttendanceTransfer(!modalStateAttendanceTransfer);
    }

    useEffect(() => {
        companyService.getCompanyByID(props.chat.companyID).then(
            response => {
                if (typeof response != "boolean") {
                    setCompanyName(response.name);
                }
            }
        )

        webhookService.listWebhooks([chat.companyID.toString()]).then(
            res => {
                setWebhooks(res);
            }
        )
    }, [props.chat, chat.companyID]);

    useEffect(() => {
        if (chat.agent) {
            setAttendantName(chat.agent!.name)
        }
    }, [chat.agent]);

    useEffect(() => {

        configService.findConfigByKeyAndCompany(chat.companyID, "discardChat").then(configResponse => {

            if (typeof configResponse === "boolean") return;

            const configurationValue = configResponse.config_value;

            try {

                const object = JSON.parse(configurationValue);

                if (object.discard != undefined && typeof object.discard == 'boolean') {

                    setDiscardChat(object.discard);
                }

            } catch (error) {
                console.log(error);
            }
        }).catch(error => { console.log(error) });

    }, []);

    useEffect(() => {

        configService.findConfigByKeyAndCompany(chat.companyID, "blockRegisterTicketWithoutAgent").then(configResponse => {

            if (typeof configResponse === "boolean") return;

            const configurationValue = configResponse.config_value;

            try {

                const object = JSON.parse(configurationValue);

                if (object.blockRegisterTicketWithoutAgent != undefined && typeof object.blockRegisterTicketWithoutAgent == 'boolean') {

                    setBlockRegisterTicketWithoutAgent(object.blockRegisterTicketWithoutAgent);
                }

            } catch (error) {
                console.log(error);
            }
        }).catch(error => { console.log(error) });

    }, []);

    useEffect(() => {

        configService.findConfigByKeyAndCompany(chat.companyID, "allowChangeUsername").then(configResponse => {

            if (typeof configResponse === "boolean") return;

            const configurationValue = configResponse.config_value;

            try {

                const object = JSON.parse(configurationValue);

                if (object.allowChangeUsername != undefined && typeof object.allowChangeUsername == 'boolean') {

                    setAllowChangeUsername(object.allowChangeUsername);
                }

            } catch (error) {
                console.log(error);
            }
        }).catch(error => { console.log(error) });

    }, []);

    useEffect(() => {
        setWebhookClose("invalid");
        departmentService.getDepartmentByCompanyID(chat.companyID).then(res => {
            setOptionsDepartment(res);
        })
        userService.listUserByCompanyID(chat.companyID).then(res => {
            setOptionsUser(res);
        });

    }, []);

    let statusCloseChat = false;

    const closeChat = () => {

        if (webhookClose == "invalid") {
            toast.error('Selecione uma opção', {
                duration: 5000,
            });
            setWaitingRequest(false);

            return
        }

        setWaitingRequest(true);

        setInvalidSelect(false)
        statusCloseChat = true;

        if (selectSearchOptions.length > 0 && selectedOption == "") {
            toast.error('Selecione uma opção', {
                duration: 5000,
            });
            setWaitingRequest(false);

            return;
        }

        const data = {
            customerUser: customerLogin,
            customerEmail: customerEmail,
            customerName: customerName,
            customerCompanyName: customerCompanyName,
            ticketTitle: ticketTitle,
            ticketIsClosed: ticketIsClosed
        };

        chatService.closeChat(props.chat.id, webhookClose, selectedOption, data).then(
            response => {
                if ((webhookClose && selectSearchOptions.length == 0) || (webhookClose && selectedOption)) {
                    toast.success(`Interacão concluída: ${chat.name} | ${response}`, {
                        duration: 10000,
                    });
                }
                statusCloseChat = false;
                togleModal();

                setWaitingRequest(false);
                dispatch(socketChatEnd(props.chat.id));
            }
        ).catch(error => {
            togleModal();
            setWaitingRequest(false);
            statusCloseChat = false;
            console.log(error);
        });
    }

    const handleChangeCustomerEmail = (event: any) => {
        setCustomerEmail(event.target.value);
    };
    const handleChangeTicketTitle = (event: any) => {
        setTicketTitle(event.target.value);
    };
    const handleChangeCustomerName = (event: any) => {
        setCustomerName(event.target.value);
    };
    const handleChangeCustomerCompanyName = (event: any) => {
        setCustomerCompanyName(event.target.value);
    };
    const togleCheckboxTicketIsClosed = (event: any) => {
        setTicketIsClosed(event.target.checked);
    };

    const toggleUserAsAttendanceAgent = () => {
        const userId = parseInt(localStorage.getItem("id") ?? "0");

        if (!userId) {
            toast.error("Ocorreu um erro interno");
            return;
        }

        if (localStorage.getItem("id") == chat.agent?.id) {
            sendRequestChangeAgent(0);

        } else {
            if (chat.agent && chat.agent.id) {
                setModalChangeAgent(true);
            } else {
                sendRequestChangeAgent(userId);
            }
        }

    }

    const handleCloseChangeAgentModal = () => {
        setModalChangeAgent(false)
    }

    async function sendRequestChangeAgent(userId = 0) {
        if (userId == -1) {
            const userId = parseInt(localStorage.getItem("id") ?? "0");

            if (!userId) {
                toast.error("Ocorreu um erro interno");
                return;
            }

            toast.promise(
                chatService.setChatAttendant(chat.id, userId)
                    .then(() => {
                        dispatch(fetchCurrentChatAndsChats(chat.id))
                    }), {
                loading: `Alterando atendente...`,
                success: `Atendente alterado!`,
                error: 'Ocorreu um problema ao alterar atendente',
            });

        } else if (userId == 0) {
            toast.promise(
                chatService.setChatAttendant(chat.id, 0)
                    .then(() => {
                        dispatch(fetchCurrentChatAndsChats(chat.id))
                    }), {
                loading: `Removendo atendente...`,
                success: `Atendente removido!`,
                error: 'Ocorreu um problema ao alterar atendente',
            });
        } else {
            toast.promise(
                chatService.setChatAttendant(chat.id, userId)
                    .then(() => {
                        dispatch(fetchCurrentChatAndsChats(chat.id))
                    }), {
                loading: `Adicionando atendente...`,
                success: `Atendente adicionado!`,
                error: 'Ocorreu um problema ao alterar atendente',
            });
        }

        setModalChangeAgent(false);
    }

    const handleChangeSelect = async (e: ChangeEvent<HTMLSelectElement>) => {

        if (e.target.value != "invalid") {
            setInvalidSelect(false);
        }

        const webhook = webhooks.find(webhook => webhook.id == e.target.value);

        if (webhook === undefined) {
            setSelectSearchOptions([]);
            setWebhookClose(e.target.value);
            return;
        }

        setWaitingRequest(true);
        setSelectedOption("");

        if (e.target.value && e.target.value != "invalid") {
            setWaitingRequest(true);
            setSelectSearchOptions([]);

            setShowCustomerFieldsOnTicketRegister(webhook.showCustomerUserInformation);
            setShowTicketFieldsOnTicketRegister(webhook.showTicketInformation);

            let customerUserLogin = "";
            let customerUserEmail = "";

            if (webhook.needCustomerEmail) {
                const url = webhook.searchCustomerUserUrl;

                await webhookService.getCustomerUserDataByPhone(url, chat.phone).then(res => {
                    if (res) {
                        setCustomerLogin(res["Login"]);
                        setCustomerEmail(res["Email"]);
                        setCustomerCompanyName(res["CompanyName"]);
                        setCustomerName(res["Name"]);
                        setIsCustomerUserFieldsBlocked(true);

                        customerUserLogin = res["Login"];
                        customerUserEmail = res["Email"];
                    }
                }).catch(error => {
                    toast.error('Não foi possível obter os dados do Usuário do Cliente.', {
                        duration: 3000,
                    });

                    setCustomerLogin(null);
                    setCustomerEmail(null);
                    setCustomerCompanyName(null);
                    setCustomerName(null);
                    setIsCustomerUserFieldsBlocked(false);
                });
            }

            if (webhook.activeSearch) {

                let data: {phone: string, customerUserLogin?: string, customerUserEmail?: string};
                data = {
                    phone: chat.phone
                };

                if(customerUserLogin) data["customerUserLogin"] = customerUserLogin;
                if(customerUserEmail) data["customerUserEmail"] = customerUserEmail;

                const existList = await webhookService.getListTickets(data, e.target.value).then(res => {
                    if (res) {
                        if (res != null) {
                            setSelectSearchOptions(res);

                            return true;
                        }
                    }
                    return false;
                }).catch(error => {
                    return false;
                });

                if (!existList) {
                    toast.error('Sem tickets disponíveis', {
                        duration: 3000,
                    });
                    setSelectSearchOptions([]);
                    setWaitingRequest(false);
                    setWebhookClose("invalid");
                    return;
                } else {
                    setWaitingRequest(false);
                    setWebhookClose(e.target.value);
                    return;
                }
            }

            setWaitingRequest(false);

        } else {

            setSelectSearchOptions([]);
            setWaitingRequest(false);
            setWebhookClose("invalid");
            return;

        }
        // buscar e atualizar o chamado
        setWebhookClose(e.target.value);
    }

    const handleChangeSelectSearch = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(e.target.value);
    }

    const [openDialogName, setOpenDialogName] = useState(false);
    const [newName, setNewName] = useState(props.chat.name);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleClickOpenDialogName = () => {
        setNewName(props.chat.name);
        setOpenDialogName(true);
    };

    const handleCloseDialogName = () => {
        if (!isUpdating) {
            setOpenDialogName(false);
        }
    };

    const handleSaveDialogName = async () => {
        setIsUpdating(true);

        if (!allowChangeUsername) return;

        if (!newName.trim()) return sendToast("Digite um nome.");
        if (newName.trim() == props.chat.name) return sendToast("Nome igual ao atual.");

        let success = false;
        const userId = localStorage.getItem("id");

        if (!userId) return sendToast("Ocorreu um erro ao tentar alterar o nome do usuário. Tente novamente mais tarde.");

        await chatService.updateNameUserChat(newName.trim(), getNumberWithNine(chat.phone), chat.companyID, userId).then(res => {
            success = res;
        });
        if (!success) return sendToast("Ocorreu um erro ao tentar alterar o nome do usuário. Tente novamente mais tarde.");
        setIsUpdating(false);

        dispatch(changeNameUserChat(chat.id, newName.trim()));
        toast.success("Nome do usuário alterado com sucesso.");
        handleCloseDialogName();
    };

    const sendToast = (text: string) => {
        setIsUpdating(false);
        return toast.error(text);
    }

    const getNumberWithNine = (phone: string) => {
        if (phone.length === 12 && !['2', '3', '4', '5'].includes(phone[4]))
            return `${phone.slice(0, 4)}9${phone.slice(4)}`;
        // else if(phone.length === 13 && !['2', '3', '4', '5'].includes(phone[4]))
        //     return `${phone.slice(0, 4)}${phone.slice(5)}`;
        else
            return phone;
    }

    useEffect(() => {
        userService.getRelationUserCompany().then(res => {
            const newData = res.map(item => ({
                value: item.id_company,
                label: item.name_company
            }));
            setOptionsCompany(newData);
        });
    }, []);

    const closeChatWindow = () => {
        dispatch(chatActions.setChat({
            ...initialChat, companyID: 0,
            id: 0,
            agent: null,
            agent_id: null,
            name: '',
            phone: '',
            picture: '',
            messages: 0,
            messages_read: 0,
            updatedAt: '',
            isGroup: false,
            departmentID: 0,
            messages_chat: [],
            history: {
                history: false,
                historys: []
            },
            extra_payload: "",
            isMessagesLoading: false
        }))
    }

    const clickFinishAttendance = () => {

        if (!!webhookClose && webhookClose != "" && webhookClose != "invalid") {
            if (showTicketFieldsOnTicketRegister) {
                if (!ticketTitle) {
                    sendToast("Título do chamado vazio");
                    return;
                }
            }

            if (showCustomerFieldsOnTicketRegister && !isCustomerUserFieldsBlocked) {
                if (!customerEmail || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(customerEmail)) {
                    sendToast("Email vazio ou inválido");
                    return;
                }

                if (!customerName) {
                    sendToast("Nome do cliente vazio");
                    return;
                }
                if (!customerCompanyName) {
                    sendToast("Nome da empresa vazio");
                    return;
                }
            }

            if (blockRegisterTicketWithoutAgent) {
                if (!props.chat.agent_id) {
                    sendToast("Nenhum atendente foi selecionado como responsável");
                    return;
                }
            }
        }

        if (!statusCloseChat) closeChat();
    }

    const handleTransferAttendance = (userSelected = "", departmentSelected = "") => {

        chatService.transferAttendanceByAgentOrDepartment(props.chat.id, departmentSelected, userSelected).then(success => {
            if (success) {
                toast.success("Atendimento transferido com sucesso");
                dispatch(fetchCurrentChatAndsChats(chat.id));
            } else {
                toast.error("Ocorreu um erro ao transferir o atendimento, tente novamente mais tarde");
            }
            setWaitingTransferRequest(false);
            togleModalStateAttendanceTransfer();
        })
    }

    const clickTransferAttendance = () => {

        let transferToAgent = !!userSelected && userSelected != "" && userSelected != "invalid";
        let transferToDepartment = !!departmentSelected && departmentSelected != "" && departmentSelected != "invalid";

        if (transferToAgent || transferToDepartment) {
            setWaitingTransferRequest(true);
            handleTransferAttendance(userSelected, departmentSelected);
            return;
        }

        toast.error("Nenhum atendente ou departamento foi selecionado");

    }

    return (
        <S.Header>
            <S.ContainerBackButton onClick={closeChatWindow}>
                <MUI.IconButton>
                    <ArrowBack></ArrowBack>
                </MUI.IconButton>
            </S.ContainerBackButton>
            <S.IconHeader>
                <S.ChatHeaderItemImage src={props.chat.picture || process.env.PUBLIC_URL + '/user.png'} />
            </S.IconHeader>


            <S.InfoRow>

                <S.InfoRowDetails>
                    {allowChangeUsername &&
                        <MUI.Tooltip title="Alterar nome" style={{ justifyContent: "center" }}>
                            <S.ProfileLinker onClick={handleClickOpenDialogName} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
                                <S.UserName>
                                    {props.chat.name}
                                </S.UserName>
                            </S.ProfileLinker>
                        </MUI.Tooltip>
                    }
                    {!allowChangeUsername &&
                        <S.UserName>
                            {props.chat.name}
                        </S.UserName>
                    }

                    <S.CompanyName>{companyName}</S.CompanyName>
                </S.InfoRowDetails>
                <S.SubHeaderRow>
                    <S.Number>
                        {!props.chat.isGroup ? props.chat.phone : 'Grupo'}
                    </S.Number>
                    <ChatHeaderAttendant chatId={props.chat.id} departtamentId={props.chat.departmentID} />
                </S.SubHeaderRow>

                <MUI.Dialog open={openDialogName} onClose={handleCloseDialogName}>
                    <MUI.DialogTitle>Alterar Nome</MUI.DialogTitle>
                    <MUI.DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Nome"
                            type="text"
                            fullWidth
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            disabled={isUpdating}
                        />
                    </MUI.DialogContent>
                    <MUI.DialogActions>
                        <MUI.Button onClick={handleCloseDialogName} color="primary" disabled={isUpdating}>
                            Cancelar
                        </MUI.Button>
                        <MUI.Button onClick={handleSaveDialogName} color="primary" disabled={isUpdating}>
                            {isUpdating ? <MUI.CircularProgress size={24} /> : 'Salvar'}
                        </MUI.Button>
                    </MUI.DialogActions>
                </MUI.Dialog>
            </S.InfoRow>

            <hr style={{ height: "50px", marginLeft: ".55rem", borderColor: "var(--text-primary)" }} />

            {props.chat.agent?.id == localStorage.getItem("id") ?? "0" ?
                <MUI.Tooltip title="Deixar atendimento">
                    <MUI.Button sx={{ color: 'green', alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: "12px" }} size='large' onClick={() => { toggleUserAsAttendanceAgent() }} disabled={props.chat.isHistory}>
                        <FaUserCheck width={'16px'} height={'16px'} color='green' />
                        ATENDENDO
                    </MUI.Button>
                </MUI.Tooltip> :
                <MUI.Tooltip title="Se tornar atendente responsável">
                    <MUI.Button sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', color: "var(--text-primary)", fontSize: "12px" }} size='large' onClick={() => { toggleUserAsAttendanceAgent() }} disabled={props.chat.isHistory}>
                        <FaUserCheck width={'16px'} height={'16px'} />
                        ATENDER
                    </MUI.Button>
                </MUI.Tooltip>
            }


            <MUI.Tooltip title="Transferir atendimento">
                <MUI.Button sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', color: "var(--text-primary)", fontSize: "12px" }} size='large' onClick={() => { togleModalStateAttendanceTransfer() }} disabled={props.chat.isHistory}>
                    <FaExchangeAlt width={'16px'} height={'16px'} />
                    TRANSFERIR
                </MUI.Button>
            </MUI.Tooltip>

            <S.ChatWrapperItemEmployeeImage>
                <ImageCompany companyID={props.chat.companyID} />
            </S.ChatWrapperItemEmployeeImage>

            {!props.chat.isGroup
                ?
                <MUI.Tooltip title="Finalizar atendimento">
                    <S.ChatItemClose>
                        <S.ChatItemCloseButton>
                            <MUI.Button startIcon={<Close />} sx={{ lineBreak: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', color: "var(--text-primary)", fontSize: "11px" }} size='large' onClick={() => { togleModal() }} disabled={props.chat.isHistory}>
                                Finalizar atendimento
                            </MUI.Button>
                        </S.ChatItemCloseButton>
                    </S.ChatItemClose>
                </MUI.Tooltip> : ""}

            <Modal
                isOpen={modalState}
                contentLabel="Modal"
                style={customStyles} >
                <S.ModalTitle>Deseja encerrar essa conversa?</S.ModalTitle>
                <p>A conversa do cliente será encerrada.</p>
                <S.WrapperContainerSelect>
                    <S.WrapperSelectWebhook>
                        <S.LabelSelectWebhook><strong>Selecione uma opção</strong></S.LabelSelectWebhook>
                        <S.SelectWebhook key="selectWebhook" onChange={e => handleChangeSelect(e)}>
                            <option value="invalid"></option>
                            {discardChat ? <option value="">Descartar chamados</option> : <></>}
                            {webhooks.length > 0 ?

                                webhooks.filter((w) => w.name != "DEFAULT__").map(webhook => {
                                    return <option key={webhook.id} value={webhook.id}>{webhook.name}</option>
                                })

                                : ""}
                        </S.SelectWebhook>
                    </S.WrapperSelectWebhook>

                    {showTicketFieldsOnTicketRegister && (!!webhookClose && webhookClose != "" && webhookClose != "invalid") ?
                        <S.WrapperSelectWebhook style={{ marginTop: '10px' }}>
                            <S.LabelSelectWebhook><strong>Informações do Chamado</strong></S.LabelSelectWebhook>

                            <TextField sx={{ width: '100%', color: 'white' }}
                                id="filled-basic"
                                size="small"
                                label="Título do Chamado"
                                InputLabelProps={{ style: { color: 'black', fontSize: '1rem' } }}
                                InputProps={{ style: { color: 'black' } }}
                                variant="outlined"
                                defaultValue={ticketTitle}
                                onChange={handleChangeTicketTitle}
                            />

                            <FormControlLabel control={<Checkbox checked={ticketIsClosed} onChange={togleCheckboxTicketIsClosed} />} label="Chamado finalizado?" />
                        </S.WrapperSelectWebhook> : <></>
                    }

                    {showCustomerFieldsOnTicketRegister && (!!webhookClose && webhookClose != "" && webhookClose != "invalid") ?
                        <S.WrapperSelectWebhook style={{ marginTop: '10px' }}>
                            <S.LabelSelectWebhook><strong>Informações do Usuário Cliente</strong></S.LabelSelectWebhook>

                            {showCustomerFieldsOnTicketRegister ?
                                <>
                                    <TextField sx={{ width: '100%', color: 'white', marginTop: '10px' }}
                                        id="filled-basic"
                                        size="small"
                                        label="Email ou Usuário do Cliente"
                                        InputLabelProps={{ style: { color: 'black', fontSize: '1rem' } }}
                                        InputProps={{ style: { color: 'black' } }}
                                        variant="outlined"
                                        defaultValue={customerEmail}
                                        onChange={handleChangeCustomerEmail}
                                        disabled={isCustomerUserFieldsBlocked}
                                    />

                                    <TextField sx={{ width: '100%', color: 'white', marginTop: '10px' }}
                                        id="filled-basic"
                                        size="small"
                                        label="Nome do Cliente"
                                        InputLabelProps={{ style: { color: 'black', fontSize: '1rem' } }}
                                        InputProps={{ style: { color: 'black' } }}
                                        variant="outlined"
                                        defaultValue={customerName}
                                        onChange={handleChangeCustomerName}
                                        disabled={isCustomerUserFieldsBlocked}
                                    />
                                    <TextField sx={{ width: '100%', color: 'white', marginTop: '10px' }}
                                        id="filled-basic"
                                        size="small"
                                        label="Empresa do Cliente"
                                        InputLabelProps={{ style: { color: 'black', fontSize: '1rem' } }}
                                        InputProps={{ style: { color: 'black' } }}
                                        variant="outlined"
                                        defaultValue={customerCompanyName}
                                        onChange={handleChangeCustomerCompanyName}
                                        disabled={isCustomerUserFieldsBlocked}
                                    />
                                </> : <></>
                            }
                        </S.WrapperSelectWebhook> : <></>
                    }

                    {selectSearchOptions && selectSearchOptions.length > 0 && (!!webhookClose && webhookClose != "" && webhookClose != "invalid") ?
                        <S.WrapperSelectWebhook style={{ marginTop: '10px' }}>
                            <S.LabelSelectWebhook><strong>Selecionar uma opção de interação: </strong></S.LabelSelectWebhook>
                            <S.SelectWebhook key="selectWebhookSearch" onChange={e => handleChangeSelectSearch(e)}>
                                <option value=""></option>
                                {
                                    (selectSearchOptions && selectSearchOptions.length > 0 && (!!webhookClose && webhookClose != "" && webhookClose != "invalid")) ? selectSearchOptions.map(select => {
                                        return <option key={select.key} value={select.key}>{select.key} | {select.value}</option>
                                    }) : ''
                                }
                            </S.SelectWebhook>
                        </S.WrapperSelectWebhook>
                        : <></>
                    }

                </S.WrapperContainerSelect>
                <S.ModalButtonRow>
                    <S.ModalButton className='activeButtonModal' onClick={() => clickFinishAttendance()} disabled={isWaitingRequest} >Concluir</S.ModalButton>
                    <S.ModalButton onClick={() => { togleModal() }}  >Cancelar</S.ModalButton>
                    {isWaitingRequest ? <S.Circle><FaSpinner /></S.Circle> : ''}
                </S.ModalButtonRow>
            </Modal>

            <Modal
                isOpen={modalStateAttendanceTransfer}
                shouldCloseOnOverlayClick={true}
                contentLabel="Modal"
                style={customStyles} >
                <S.ModalTitle>Encaminhar atendimento</S.ModalTitle>

                <p>O cliente será transferido para um atendente ou departamento</p>

                <S.WrapperContainerSelect>
                    <S.WrapperSelectWebhook>
                        <S.LabelSelectWebhook><strong>Transferir para um atendente</strong></S.LabelSelectWebhook>
                        <S.SelectWebhook
                            disabled={optionsUser ? false : true}
                            key="selectUserInput"
                            onChange={e => handleChangeUserSelect(e)}>
                            <option value="invalid">--</option>
                            {optionsUser && optionsUser.length > 0 ?

                                optionsUser.map(user => {
                                    return <option key={user.id} value={user.id}>{user.name}</option>
                                })

                                : ""}
                        </S.SelectWebhook>
                    </S.WrapperSelectWebhook>

                    <S.WrapperSelectWebhook>
                        <S.LabelSelectWebhook><strong>Transferir para um departamento</strong></S.LabelSelectWebhook>
                        <S.SelectWebhook
                            disabled={optionsDepartment ? false : true}
                            key="selectDepartmentInput"
                            onChange={e => handleChangeDepartmentSelect(e)}>
                            <option value="invalid">--</option>
                            {optionsDepartment && optionsDepartment.length > 0 ?

                                optionsDepartment.map(department => {
                                    return <option key={department.id} value={department.id}>{department.name}</option>
                                })

                                : ""}
                        </S.SelectWebhook>
                    </S.WrapperSelectWebhook>
                </S.WrapperContainerSelect>

                <S.ModalButtonRow>
                    <S.ModalButton className='activeButtonModal' onClick={() => clickTransferAttendance()} disabled={isWaitingTransferRequest} >Transferir</S.ModalButton>
                    <S.ModalButton onClick={() => { togleModalStateAttendanceTransfer() }} >Cancelar</S.ModalButton>
                    {isWaitingTransferRequest ? <S.Circle><FaSpinner /></S.Circle> : ''}
                </S.ModalButtonRow>

            </Modal>

            <StyledDialog
                open={modalChangeAgent}
                onClose={handleCloseChangeAgentModal}>
                <div style={{ padding: '1rem', background: "var(--backgroundModal)" }}>

                    <ModalTitle>Atenção</ModalTitle>
                    <p>Esse cliente já está sendo atendido por {props.chat.agent?.name}. Deseja se tornar o atendente responsável?</p>

                    <ModalButtonRow style={{ alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
                        <MUI.Button size='large' sx={{ color: 'var(--primary)' }} color="secondary" onClick={handleCloseChangeAgentModal}>Não</MUI.Button>
                        <MUI.Button size='medium' variant="contained" color="success" onClick={() => { sendRequestChangeAgent(-1) }}>Sim</MUI.Button>
                    </ModalButtonRow>

                </div>
            </StyledDialog>

        </S.Header>
    )


}
export default ChatHeader;
